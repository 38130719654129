<template>
  <div class="login">
    <h1>Vui lòng đợi. Hệ thống đang chuyển sang trang đăng nhập</h1>
    <b-loading :is-full-page="true" v-model="isLoading" />
  </div>
</template>
<script>
import {WEBSITE} from '@/config.js'
export default{
  data(){
    return{
      isLoading:true,
    }
  },
  mounted () {
    setTimeout(()=>{
      window.location = `https://staff.tomorder.jp/?url=${WEBSITE}/auth`
    },500)
  }
}
</script>